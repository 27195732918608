@import "minima";

article, .post-list {
  img {
    border: 1px solid #dedede;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  img + em {
    text-align: center;
    display: block;
    font-size: 0.9em;
    margin-top: 0.3em;
  }
}

.post-list {
  li {
    margin-bottom: 5em;
  }
}

hr {
  margin-top: 3em;
  margin-bottom: 3em;
}

.heading-anchor {
  opacity: 0;
  font-size: 0.75em;

  &:hover {
    opacity: 1;
    text-decoration: none;
  }
}